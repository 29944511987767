import { useCallback, useEffect, useRef } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { EclipseBlur } from 'styles/styled';
import { breakPoints } from 'constant/breakPoints';

const WaveImages = dynamic(() => import('./WaveImages'), {
  ssr: false,
});
const ParticlesCamera = dynamic(() => import('./ParticlesCamera'), {
  ssr: false,
});

const Life = (): JSX.Element => {
  const refEle = useRef<HTMLDivElement>(null);
  const refMark = useRef<HTMLDivElement>(null);

  const onScroll = useCallback((e) => {
    if (!refMark.current || !refEle.current) return;

    const changeTranslateY =
      refMark.current.offsetTop - e.target.scrollingElement.scrollTop;

    refEle.current.style.transform = `translateY(${
      changeTranslateY > 400 ? 400 : changeTranslateY
    }px)`;
  }, []);

  useEffect(() => {
    if (!process.browser) return;

    if (window.innerWidth <= breakPoints.tabletS) {
      refEle.current?.classList.add('animation');

      return;
    }

    if ((window as any).safari) {
      refEle.current?.classList.add('safari');
    }

    // Create the observer, same as before:
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          refEle.current?.classList.add('animation');

          document.addEventListener('scroll', onScroll);
          return;
        } else {
          document.removeEventListener('scroll', onScroll);
        }
      });
    });

    refEle.current && observer.observe(refEle.current);
  }, [onScroll]);

  return (
    <Mark ref={refMark}>
      <EclipseBlur
        width="1000px"
        top="0"
        left="0"
        translateX="-54.86%"
        translateY="-44.87%"
      />
      <EclipseBlur
        width="800px"
        bottom="0"
        right="0"
        translateX="55.91%"
        translateY="43.95%"
      />
      <Wrap id="life-wave-image" ref={refEle}>
        <WaveImages />
        <ParticlesCamera />

        <Text>Life Of Spirity</Text>
        <Des>
          <p>
            We are more than just a team though.
            <br /> We are a family.
          </p>
          <Link href="/career" prefetch={false}>
            <a className="font-medium button-link">Join us</a>
          </Link>
        </Des>
      </Wrap>
    </Mark>
  );
};

export default Life;

const Mark = styled.section`
  position: relative;
  height: max-content;
`;

const aniText = keyframes`
  0%{
    transform: translateX(50%);
    opacity: 0;
  }

  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;
const Text = styled.div`
  position: absolute;
  -webkit-text-stroke: 2.69754px #ffffff;
  color: transparent;
  font-size: 152.454px;
  line-height: 130%;
  letter-spacing: 0.08em;
  font-weight: 600;
  z-index: 1;
  transform: translateX(50%);
  opacity: 0;
  left: 9.375%;
  bottom: calc(10.111vh + 240px);

  @media screen and (max-width: ${breakPoints.desktopM - 1}px) {
    font-size: 100px;
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    font-size: 80px;
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    font-size: 40px;
    -webkit-text-stroke: 1px #ffffff;
    transform: translateX(-50%);
    opacity: 1;
    left: 50%;
    top: 10px;
    bottom: initial;
    animation: none !important;
    width: max-content;
  }
`;

const aniDes = keyframes`
  0%{
    transform: translateY(50%);
    opacity: 0;
  }

  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;
const Des = styled.div`
  position: absolute;
  bottom: 10.111vh;
  left: 57.58%;
  opacity: 0;
  transform: translateY(50%);

  p {
    position: relative;
    max-width: 324px;

    &:before {
      position: absolute;
      content: '';
      bottom: -24px;
      width: 75px;
      height: 4px;
      background-color: #fff;
      box-shadow: 0px 0px 2px #fdd835;
    }
  }

  a {
    margin-top: 64px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    top: initial;
    transform: none;
    opacity: 1;
    animation: none !important;

    p {
      margin: 0 auto;
      max-width: 100%;

      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const Wrap = styled.div`
  margin-bottom: 107px;
  height: 100vh;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  transition: transform 0.2s ease;
  transform: translateY(200px);
  justify-content: space-between;

  /* Canvas */
  .dom-gl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & > div {
    z-index: 1;
  }

  &.safari {
    transform: none !important;
    margin-bottom: 210px;
    margin-top: 14%;
  }

  &.animation {
    ${Text} {
      animation: ${aniText} 1s ease 0.5s 1 forwards;
    }

    ${Des} {
      animation: ${aniDes} 1s ease 1.5s 1 forwards;
    }
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    transform: translateY(0);
    margin-top: 100px;
    padding-bottom: 80px;
    display: block;
  }
`;
